import { Box, Typography } from '@mui/material'
import LinearProgress from '@mui/material/LinearProgress'
import { AvatarGroup, AvatarUser } from 'components/common/Avatar'
import Flex from 'components/common/Flex'
import { useModal } from 'contexts/ModalContext'
import PollResponseModal from './PollResponseModal'

const PollContent = ({ pollData, rsvps }) => {
  const { entries } = pollData
  const { showModal } = useModal()
  // Sort the entries based on the number of responses each choice has
  const sortedEntries = [...entries].sort(
    (a, b) => (b.responses?.length || 0) - (a.responses?.length || 0)
  )
  const handleOpenPollResponseListModal = (e, array, index) => {
    e.stopPropagation()

    showModal(<PollResponseModal message={pollData.message} choices={array} index={index} />)
  }

  return (
    <Flex>
      {sortedEntries.map((choice, index) => (
        <Box key={index} sx={{ my: '8px' }}>
          <Flex row justifyContent="space-between">
            <Typography>{choice.name}</Typography>
            <AvatarGroup
              small
              max={4}
              onClick={(e) => handleOpenPollResponseListModal(e, sortedEntries, index)}
            >
              {choice.responses?.map(({ user }) => {
                console.log('user in poll', user)
                return <AvatarUser showTooltip key={user} userId={user} />
              })}
            </AvatarGroup>
          </Flex>
          <LinearProgress value={(choice.responses?.length / rsvps.data.length) * 100 || 0} />
        </Box>
      ))}
    </Flex>
  )
}

export default PollContent
