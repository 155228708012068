import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { useGetUser } from 'api/hooks/get'
import EllipsisMenu from 'components/common/EllipsisMenu'
import Flex from 'components/common/Flex'
import { useEvent } from 'contexts/EventContext'
import { useModal } from 'contexts/ModalContext'
import { useAuth } from 'hooks/useAuth'
import PropTypes from 'prop-types'
import { formatDate } from 'utils'
import TravelAddEditModal from './TravelAddEditModal'
import TravelDeleteModal from './TravelDeleteModal'

const TravelItem = ({ plan }) => {
  const { showModal } = useModal()
  const { isHost } = useEvent()
  const { user } = useAuth()

  const isOwner = isHost || user.id === plan.user_id

  const fetchUser = useGetUser(plan.user_id)
  const { first_name, last_name } = fetchUser.data.id ? fetchUser.data : user

  const items = [
    {
      name: 'Edit',
      onClick: () => showModal(<TravelAddEditModal arrival={plan.arrival} departure={plan.departure}/>),
    },
    {
      name: 'Delete',
      onClick: () => showModal(<TravelDeleteModal user_id={user.id} />),
    },
  ]

  return (
    <TableRow key={plan.user_id}>
      <TableCell>{`${first_name} ${last_name}`}</TableCell>
      <TableCell>
        <Flex column sx={{ whiteSpace: 'nowrap' }}>{`${formatDate(
          plan.arrival.date_time,
          'Comment'
        )}`}</Flex>
        <Flex> {`(${plan.arrival.location})`}</Flex>
      </TableCell>
      <TableCell>
        <Flex column sx={{ whiteSpace: 'nowrap' }}>
          {`${formatDate(plan.departure.date_time, 'Comment')}`}
        </Flex>
        <Flex>{`(${plan.departure.location})`}</Flex>
      </TableCell>
      <TableCell>
        <Flex
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {isOwner ? (
            <EllipsisMenu items={items} sx={{ fontSize: '14px', ml: '8px' }} />
          ) : (
            // spacing for when EllipsisMenu is hidden
            <Flex sx={{ width: '22px' }} />
          )}
        </Flex>
      </TableCell>
    </TableRow>
  )
}

const TravelTable = ({ plans, isAdmin, onSort, sortColumn, sortDirection }) => {

  const renderSortIcon = (column) => {
    if (sortColumn !== column) return null
    return sortDirection === 'asc' ? <ArrowDropUp /> : <ArrowDropDown />
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell onClick={() => onSort('name')} className="cursor-pointer">
            <Flex row>Name</Flex>
          </TableCell>
          <TableCell onClick={() => onSort('arrival')} className="cursor-pointer">
            <Flex row>Arrival {renderSortIcon('arrival')}</Flex>
          </TableCell>
          <TableCell onClick={() => onSort('departure')} className="cursor-pointer">
            <Flex row>Departure {renderSortIcon('departure')}</Flex>
          </TableCell>
          {isAdmin && <TableCell>Actions</TableCell>}
        </TableRow>
      </TableHead>
      <TableBody>
        {plans.map((plan) => (
          <TravelItem key={plan.id} plan={plan} />
        ))}
      </TableBody>
    </Table>
  )
}

TravelTable.propTypes = {
  plans: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      arrival: PropTypes.shape({
        time: PropTypes.string.isRequired,
        location: PropTypes.string.isRequired,
      }).isRequired,
      departure: PropTypes.shape({
        time: PropTypes.string.isRequired,
        location: PropTypes.string.isRequired,
      }).isRequired,
    })
  ).isRequired,
  isAdmin: PropTypes.bool.isRequired,
  onSort: PropTypes.func.isRequired,
  sortColumn: PropTypes.string,
  sortDirection: PropTypes.oneOf(['asc', 'desc']).isRequired,
}

export default TravelTable
