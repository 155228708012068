import { LoadingButton } from '@mui/lab'
import { Typography } from '@mui/material'
import { useDeleteTravel } from 'api/hooks/delete'
import Flex from 'components/common/Flex'
import { useForm } from 'react-hook-form'


const TravelDeleteModal = ({user_id}) => {
  const { mutateAsync: deleteTravel } = useDeleteTravel()

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm()

  const onSubmit = async () => { 
    const travel = {'user_id': user_id}
    await deleteTravel(travel)
  }

  return (
    <Flex component="form" gap="16px" onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h6" alignSelf="center">
        Delete Itinerary
      </Typography>
      <Typography>
        Are you sure you want to delete?
      </Typography>
      <LoadingButton
        type="submit"
        sx={{ alignSelf: 'flex-end' }}
        loading={isSubmitting}
      >
        Delete
      </LoadingButton>
    </Flex>
  )
}

export default TravelDeleteModal
