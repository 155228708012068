import { LoadingButton } from '@mui/lab'
import Flex from 'components/common/Flex'
import Loading from 'components/common/Loading'
import TravelAddEditModal from 'components/Event/Travel/TravelAddEditModal'
import TravelTable from 'components/Event/Travel/TravelTable'
import { useEvent } from 'contexts/EventContext'
import { useModal } from 'contexts/ModalContext'
import { useAuth } from 'hooks/useAuth'
import { useState } from 'react'

const Travel = () => {
  const [sortColumn, setSortColumn] = useState(null)
  const [sortDirection, setSortDirection] = useState('asc')
  const { showModal } = useModal()
  const { user } = useAuth()

  // get travel details
  const { fetchTravel } = useEvent()
  if (fetchTravel.isLoading) return <Loading size="32px" />

  const travelPlans = fetchTravel.data

  const hasResponded = travelPlans.some((entry) => entry.user_id === user.id)

  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')
    } else {
      setSortColumn(column)
      setSortDirection('asc')
    }
  }

  const sortedPlans = [...travelPlans].sort((a, b) => {
    if (!sortColumn) return 0
  
    let aValue, bValue
    if (sortColumn === 'arrival') {
      aValue = new Date(a.arrival.date_time)
      bValue = new Date(b.arrival.date_time)
    } else if (sortColumn === 'departure') {
      aValue = new Date(a.departure.date_time)
      bValue = new Date(b.departure.date_time)
    } else {
      aValue = a[sortColumn]
      bValue = b[sortColumn]
    }
  
    if (aValue < bValue) return sortDirection === 'asc' ? -1 : 1
    if (aValue > bValue) return sortDirection === 'asc' ? 1 : -1
    return 0
  })

  return (
    <Flex>
      <h1 className="text-2xl font-bold mb-4">Travel Planner</h1>
      <TravelTable
        plans={sortedPlans}
        onSort={handleSort}
        sortColumn={sortColumn}
        sortDirection={sortDirection}
      />
      <Flex row justifyContent="flex-end" gap="4px">
        {!hasResponded && (
          <LoadingButton onClick={() => showModal(<TravelAddEditModal />)}>
            Add Itinerary
          </LoadingButton>
        )}
      </Flex>
    </Flex>
  )
}

export default Travel
