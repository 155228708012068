import { useMutation, useQueryClient } from '@tanstack/react-query'
import { getAuthToken, useAuth } from 'hooks/useAuth'
import { useNavigate, useParams } from 'react-router-dom'

const server = process.env.REACT_APP_SERVER

export const useDeleteEvent = () => {
  const { user } = useAuth()
  const { eventId } = useParams()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  return useMutation({
    mutationFn: () =>
      fetch(`${server}/events/${eventId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: getAuthToken(user.id),
        },
      }),
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: ['rsvp-events'] })
      navigate('/events', { replace: true })
    },
  })
}

export const useDeleteComment = () => {
  const { user } = useAuth()
  const { eventId } = useParams()
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ comment_id }) =>
      fetch(`${server}/comments/${comment_id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: getAuthToken(user.id),
        },
      }),
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: ['comments', eventId],
      })
    },
  })
}

export const useDeleteTask = () => {
  const { user } = useAuth()
  const queryClient = useQueryClient()
  const { eventId } = useParams()
  return useMutation({
    mutationFn: ({ task_id }) =>
      fetch(`${server}/tasks/${task_id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: getAuthToken(user.id),
        },
      }),
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: ['tasks', { eventId }],
      })
    },
  })
}

export const useDeletePoll = () => {
  const { user } = useAuth()
  const queryClient = useQueryClient()
  const { eventId } = useParams()
  return useMutation({
    mutationFn: (poll_id) =>
      fetch(`${server}/polls/${poll_id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: getAuthToken(user.id),
        },
      }),
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: ['polls', eventId],
      })
    },
  })
}


export const useDeleteTravel = () => {
  const { user } = useAuth()
  const queryClient = useQueryClient()
  const { eventId } = useParams()
  return useMutation({
    mutationFn: (travel) => {
      return fetch(`${server}/events/${eventId}/travel_entries`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: getAuthToken(user.id),
        },
        body: JSON.stringify(travel),
      })
    },
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: ['travel', eventId],
      })
    },
  })
}