import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useModal } from 'contexts/ModalContext'
import { useSnackbar } from 'contexts/SnackbarContext'
import { getAuthToken, useAuth } from 'hooks/useAuth'
import { useNavigate, useParams } from 'react-router-dom'

const server = process.env.REACT_APP_SERVER

export const usePatchUser = () => {
  const { user } = useAuth()
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async ({ first_name, last_name }) => {
      const response = await fetch(`${server}/users/${user.id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: getAuthToken(user.id),
        },
        body: JSON.stringify({ first_name, last_name }),
      })

      return await response.json()
    },
    onSuccess: async ({ id }) => {
      await queryClient.refetchQueries({
        queryKey: ['users', id],
      })
    },
  })
}

export const usePatchEvent = () => {
  const { openSnackbar } = useSnackbar()
  const { user } = useAuth()
  const queryClient = useQueryClient()
  const { eventId } = useParams()
  const navigate = useNavigate()
  return useMutation({
    mutationFn: async (event) => {
      const response = await fetch(`${server}/events/${eventId}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: getAuthToken(user.id),
        },
        body: JSON.stringify(event),
      })
      return await response.json()
    },
    onSuccess: async ({ id }) => {
      openSnackbar('Event updated!')
      await queryClient.refetchQueries({ queryKey: ['events', id] })
      navigate(`/events/${id}`)
    },
  })
}

export const usePatchComment = () => {
  const { user } = useAuth()
  const { eventId } = useParams()
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async ({ body, commentId }) => {
      const response = await fetch(`${server}/comments/${commentId}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: getAuthToken(user.id),
        },
        body: JSON.stringify({ body }),
      })
      return await response.json()
    },
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: ['comments', eventId],
      })
    },
  })
}

export const usePatchRegistry = () => {
  const { user } = useAuth()
  const { eventId } = useParams()
  const { hideModal } = useModal()
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['patchRegistry', eventId],
    mutationFn: async ({ entries }) => {
      const response = await fetch(`${server}/events/${eventId}/registry`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: getAuthToken(user.id),
        },
        body: JSON.stringify({
          entries,
        }),
      })
      return await response.json()
    },
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: ['registry', eventId],
      })
      hideModal()
    },
  })
}

export const usePatchTask = () => {
  const { user } = useAuth()
  const { eventId } = useParams()
  const queryClient = useQueryClient()
  const { openSnackbar } = useSnackbar()
  const { hideModal } = useModal()

  return useMutation({
    mutationFn: async ({ task, taskPayload }) => {
      const response = await fetch(`${server}/events/${eventId}/tasks/${task.id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: getAuthToken(user.id),
        },
        body: JSON.stringify(taskPayload),
      })

      if (!response.ok) {
        openSnackbar('Error updating task')
        return
      }

      return await response.json()
    },
    onMutate: async ({ task, taskPayload, isCompleted }) => {
      if (isCompleted === undefined) return

      // optmistic update if changing task status for both attendee and host
      const queryKeys = !taskPayload.state
        ? [
            { eventId, isCompleted: false },
            { eventId, isCompleted: true },
          ]
        : [
            { eventId, state: 'PENDING' },
            { eventId, state: 'COMPLETED' },
          ]

      const removeTask = (tasks, task) => {
        return {
          ...tasks,
          pages: [
            {
              ...tasks.pages[0],
              _embedded: {
                taskDetails: tasks.pages[0]._embedded.taskDetails.filter((t) => t.id !== task.id),
              },
            },
          ],
        }
      }

      const addTask = (tasks, task) => {
        return {
          ...tasks,
          pages: [
            {
              ...tasks.pages[0],
              _embedded: {
                // sort by respond_by -> message aka same as BE
                taskDetails: [...(tasks.pages[0]._embedded?.taskDetails ?? []), task].sort(
                  (a, b) => {
                    let dateA = new Date(a.respond_by)
                    let dateB = new Date(b.respond_by)
                    if (dateA < dateB) return -1
                    if (dateA > dateB) return 1
                    return a.message.localeCompare(b.message)
                  }
                ),
              },
            },
          ],
        }
      }
      // update incompleted task cache
      queryClient.setQueryData(['tasks', queryKeys[0]], (tasks) => {
        return isCompleted ? removeTask(tasks, task) : addTask(tasks, task)
      })

      // update completed task cache
      queryClient.setQueryData(['tasks', queryKeys[1]], (tasks) => {
        return isCompleted ? addTask(tasks, task) : removeTask(tasks, task)
      })
    },
    onSuccess: async (_, { taskPayload, isCompleted }) => {
      // host: state -> refetch attendee (badgeCount)
      if (taskPayload.state !== undefined) {
        queryClient.refetchQueries({
          queryKey: ['tasks', { eventId, isCompleted: false }],
        })
        queryClient.refetchQueries({
          queryKey: ['tasks', { eventId, isCompleted: true }],
        })
        // attendee: isCompleted -> refetch host
      } else if (isCompleted !== undefined) {
        queryClient.refetchQueries({
          queryKey: ['tasks', { eventId, state: 'COMPLETED' }],
        })
        queryClient.refetchQueries({
          queryKey: ['tasks', { eventId, state: 'PENDING' }],
        })
        // host: patch without state or is_completed -> refetch all
      } else {
        await queryClient.refetchQueries({
          queryKey: ['tasks', { eventId }],
        })
        hideModal()
      }
    },
  })
}

export const usePatchPoll = () => {
  const { user } = useAuth()
  const queryClient = useQueryClient()
  const { eventId } = useParams()
  const { openSnackbar } = useSnackbar()
  const { hideModal } = useModal()
  return useMutation({
    mutationFn: async ({ pollPayload }) => {
      const { pollId, payload } = pollPayload
      const response = await fetch(`${server}/polls/${pollId}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: getAuthToken(user.id),
        },
        body: JSON.stringify(payload),
      })
      return await response.json()
    },
    onSuccess: async () => {
      openSnackbar('Poll updated!')
      await queryClient.refetchQueries({
        queryKey: ['polls', eventId],
      })
      hideModal()
    },
  })
}

export const usePatchRsvp = () => {
  const { user } = useAuth()
  const { eventId } = useParams()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  return useMutation({
    mutationFn: async (rsvp) => {
      const response = await fetch(`${server}/events/${eventId}/rsvp`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: getAuthToken(user.id),
        },
        body: JSON.stringify(rsvp),
      })

      return await response.json()
    },
    onSuccess: async ({ attending }) => {
      await Promise.all([
        queryClient.refetchQueries({ queryKey: ['rsvps', eventId] }),
        queryClient.refetchQueries({ queryKey: ['users', eventId] }),
        queryClient.refetchQueries({ queryKey: ['events', eventId] }),
      ])
      if (attending !== 'YES') navigate(`/events/${eventId}#about`)
    },
  })
}

export const usePatchTravel = () => {
  const { user } = useAuth()
  const queryClient = useQueryClient()
  const { eventId } = useParams()
  const { openSnackbar } = useSnackbar()
  const { hideModal } = useModal()
  return useMutation({
    mutationFn: async (travel) => {
      const response = await fetch(`${server}/events/${eventId}/travel_entries`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: getAuthToken(user.id),
        },
        body: JSON.stringify(travel),
      })
      return await response.json()
    },
    onSuccess: async () => {
      openSnackbar('Travel updated!')
      await queryClient.refetchQueries({
        queryKey: ['travel', eventId],
      })
      hideModal()
    },
  })
}

