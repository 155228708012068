import LoadingButton from '@mui/lab/LoadingButton'
import { Typography } from '@mui/material'
import { useGetUser } from 'api/hooks/get'
import { usePatchTravel } from 'api/hooks/patch'
import { usePostTravel } from 'api/hooks/post'
import Flex from 'components/common/Flex'
import dayjs from 'dayjs'
import { useAuth } from 'hooks/useAuth'
import { DateTimePicker, TextField } from 'inputs/ControlledInputs'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { getUTCDateTime } from 'utils'

const TravelAddEditModal = ({ arrival, departure }) => {
  const { user } = useAuth()
  const fetchUser = useGetUser()
  const { id } = fetchUser.data.id ? fetchUser.data : user
  const { mutateAsync: createItinerary } = usePostTravel()
  const { mutateAsync: editItinerary } = usePatchTravel()

  const isAdd = !arrival

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      arrival_time: arrival?.date_time ? dayjs(arrival.date_time) : null,
      arrival_location: arrival?.location ? arrival.location : null,
      departure_time: departure?.date_time ? dayjs(departure.date_time) : null,
      departure_location: departure?.location ? departure.location : null,
    },
  })

  const onSubmit = async (formValues) => {
    const travelItem = {
      user_id: id,
      arrival: {
        date_time: getUTCDateTime(formValues.arrival_time, formValues.arrival_time),
        location: formValues.arrival_location,
      },
      departure: {
        date_time: getUTCDateTime(formValues.departure_time, formValues.departure_time),
        location: formValues.departure_location,
      },
    }
    isAdd ? await createItinerary(travelItem) : await editItinerary(travelItem)
  }

  return (
    <Flex component="form" gap="16px" onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h6">Add Itinerary</Typography>
      <Flex row gap="8px" alignItems="flex-start">
        <DateTimePicker
          control={control}
          name="arrival_time"
          label="Arrival"
          disabled={isSubmitting}
          rules={{ required: 'Required' }}
        />
        <TextField
          control={control}
          name="arrival_location"
          label="Arrival Location"
          disabled={isSubmitting}
          rules={{
            required: 'Required',
          }}
          sx={{ flex: '0 0 40%' }}
        />
      </Flex>

      <Flex row gap="8px" alignItems="flex-start">
        <DateTimePicker
          control={control}
          name="departure_time"
          label="Departure"
          disabled={isSubmitting}
          rules={{ required: 'Required' }}
        />
        <TextField
          control={control}
          name="departure_location"
          label="Departure Location"
          disabled={isSubmitting}
          rules={{
            required: 'Required',
          }}
          sx={{ flex: '0 0 40%' }}
        />
      </Flex>

      <LoadingButton
        type="submit"
        sx={{ alignSelf: 'flex-end' }}
        onClick={handleSubmit}
        loading={isSubmitting}
      >
        Save
      </LoadingButton>
    </Flex>
  )
}

export default TravelAddEditModal

TravelAddEditModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}
